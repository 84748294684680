.root {
  display: flex;
}

.control {
  flex: 1;
  background-color: var(--mantine-color-gray-0);
  border: 1px solid var(--mantine-color-gray-3);
  padding: var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-sm);
  transition: border-color 100ms ease;
  box-shadow: var(--mantine-shadow-xs);
  cursor: pointer;

  &:hover{
    border: 1px solid var(--mantine-primary-color-filled);
  }

  /* &[data-checked] {
    border-color: var(--mantine-color-blue-filled);
  } */
}

[data-mantine-color-scheme='dark'] .control {
  background-color: var(--mantine-color-dark-7);

  &:hover{
    border: 1px solid var(--mantine-color-brand-6);
  }
}

.label {
  line-height: 1.3;
}