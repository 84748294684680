.searchBox {
  width: 240px;
}

.itemList {
  cursor: pointer;
}
  
.tag {
  color: var(--mantine-color-gray-6);
  background-color: var(--mantine-color-gray-2);
  font-size: var(--mantine-font-size-xs);
  text-transform: none;
}

[data-mantine-color-scheme='dark'] .tag {
  background-color: var(--mantine-color-gray-8);
}