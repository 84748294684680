.iconWrapper img, .iconWrapper svg {
  width: 48px;
  height: 48px;
  vertical-align: top;
}

.driverButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--mantine-radius-md);
  height: 120px;
  width: 200px;
  background-color: var(--mantine-color-gray-0);
  transition: box-shadow 150ms ease, transform 100ms ease;

  &:hover {
    box-shadow: var(--mantine-shadow-md) !important;
    transform: scale(1.03);
  }
}

[data-mantine-color-scheme='dark'] .driverButton {
  background-color: var(--mantine-color-dark-5);
}


.driverButtonSelected {
  background-color: var(--mantine-primary-color-light);
  border: 1px solid var(--mantine-primary-color-7);
}

[data-mantine-color-scheme='dark'] .driverButtonSelected {
  background-color: var(--mantine-primary-color-light);
  border: 1px solid var(--mantine-primary-color-3);
}