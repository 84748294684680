.wrapper {
  display: flex;
}

.aside {
  flex: 0 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.asideExpanded {
  flex: 0 0 200px;
}

.sideMenu {
  width: 400px;
}

.sideMenuHeader {
  box-sizing: border-box;
  padding: var(--mantine-spacing-xs);
  min-height: 70px;
  border-bottom: 1px solid var(--mantine-color-gray-3);
}

[data-mantine-color-scheme='dark'] .sideMenuHeader {
  border-bottom: 1px solid var(--mantine-color-dark-5);
}

.mainLink {
  width: 44px;
  height: 44px;
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: var(--mantine-spacing-xs);
}

.mainLinkExpanded {
  width: 180px;
  justify-content: flex-start;
  padding-left: var(--mantine-spacing-xs);
}

.menuLink {
  width: 44px;
  height: 44px;
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuLinkExpanded {
  width: 180px;
  justify-content: flex-start;
  padding-left: var(--mantine-spacing-xs);
}

.mainLinkExpandedIcon {
  justify-content: flex-start;
}

.navBarItemSelected {
  background-color: var(--mantine-primary-color-light);
  color: var(--mantine-primary-color-filled);
}

[data-mantine-color-scheme='dark'] .navBarItemSelected {
  background-color: var(--mantine-primary-color-light);
  color: var(--mantine-color-brand-6);
}

.logo {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  box-sizing: border-box;
  padding: var(--mantine-spacing-xs);
  min-height: 70px;
  max-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 750px) {
  .header {
    min-height: 50px;
    max-height: 50px;
  }
}

.content {
  flex-grow: 1;
}

.fullHeightContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerTitleContaner {
  gap: 0;
  max-height: 50px;
}

.searchBox {
  width: 300px;
}

.infoMessage {
  background-color: var(--mantine-color-blue-0);
  color: var(--mantine-color-gray-9);
}

[data-mantine-color-scheme='dark'] .infoMessage {
  background-color: var(--mantine-color-blue-6);
  color: var(--mantine-color-gray-0);
}

.errorMessage {
  background-color: var(--mantine-color-red-0);
  color: var(--mantine-color-gray-9);
}

[data-mantine-color-scheme='dark'] .errorMessage {
  background-color: var(--mantine-color-red-4);
}

.warningMessage {
  background-color: var(--mantine-color-yellow-0);
  color: var(--mantine-color-gray-9);
}

[data-mantine-color-scheme='dark'] .warningMessage {
  background-color: var(--mantine-color-yellow-4);
}

.tagCounter {
  color: var(--mantine-color-gray-7);
  border: none;
}

.titleList {
  color: var(--mantine-primary-color-filled);
  white-space: break-spaces;
  word-break: break-word;
}

[data-mantine-color-scheme='dark'] .titleList {
  color: var(--mantine-color-brand-6);
}

.tag {
  color: var(--mantine-color-gray-6);
  background-color: var(--mantine-color-gray-2);
  font-size: var(--mantine-font-size-xs);
  text-transform: none;
}

[data-mantine-color-scheme='dark'] .tag {
  background-color: var(--mantine-color-gray-8);
}

.toolbar {
  z-index: 100;
  position: relative;
}

.toolbarSticky {
  position: fixed;
  top: 20px;
  padding: var(--mantine-spacing-xs);
  background-color: var(--mantine-color-gray-2);
  box-shadow: var(--mantine-shadow-xs);
}

[data-mantine-color-scheme='dark'] .toolbarSticky {
  background-color: var(--mantine-color-gray-8);
}

.inputWarning {
  input {
    border-color: var(--mantine-color-yellow-5);
    background-color: var(--mantine-color-yellow-0);
  }
}

.radio {
  padding: var(--mantine-spacing-xs);
}

.radioSelected {
  border-radius: var(--mantine-radius-xs);
  border: 1px solid var(--mantine-color-dark-2);
  background-color: var(--mantine-color-gray-2);
}

[data-mantine-color-scheme='dark'] .radioSelected {
  background-color: var(--mantine-color-dark-5);
}

.progressLabel {
  font-family: Greycliff CF, var(--mantine-font-family);
  line-height: 1;
  font-size: var(--mantine-font-size-sm);
}

.stat {
  border-bottom: 3px solid;
  padding-bottom: 5px;
}

.statCount {
  font-family: Greycliff CF, var(--mantine-font-family);
  line-height: 1.3;
}

.diff {
  font-family: Greycliff CF, var(--mantine-font-family);
  display: flex;
  align-items: center;
}

.icon {
  color: var(--mantine-color-gray-4);
}

[data-mantine-color-scheme='dark'] .icon {
  color: var(--mantine-color-dark-3);
}

.switchButton {
  display: flex;
  border-radius: var(--mantine-radius-sm);
  box-shadow: var(--mantine-shadow-xs);
  padding: var(--mantine-spacing-lg);
  background-color: var(--mantine-color-gray-0);
  border: 1px solid var(--mantine-color-gray-3);

  &:hover{
    border: 1px solid var(--mantine-primary-color-filled);
  }
}

[data-mantine-color-scheme='dark'] .switchButton {
  background-color: var(--mantine-color-dark-7);

  &:hover{
    border: 1px solid var(--mantine-color-brand-6);
  }
}

.floatingButton {
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 100;
  box-shadow: var(--mantine-shadow-sm);
}

.floatingLanguageControl {
  position: fixed;
  right: 70px;
  bottom: 18px;
  z-index: 100;
}

.chatUserMessage {
  background-color: var(--mantine-primary-color-light);
  text-align: right;
}

[data-mantine-color-scheme='dark'] .chatUserMessage {
  background-color: var(--mantine-primary-color-light);
}

.chatBotMessage {
  background-color: var(--mantine-color-gray-0);
}

[data-mantine-color-scheme='dark'] .chatBotMessage {
  background-color: var(--mantine-color-dark-4);
}

.chatAttachFile {
  color: mantine-var(--mantine-color-white);
  background-color: var(--mantine-primary-color-filled);
  font-size: var(--mantine-font-size-xs);
  text-transform: none;
}

[data-mantine-color-scheme='dark'] .chatAttachFile {
  background-color: var(--mantine-primary-color-filled);
}

.homeFeatureCard {
  background-color: var(--mantine-color-gray-0);
  border: 1px solid var(--mantine-color-gray-3) !important;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--mantine-primary-color-filled) !important;
  }
}

[data-mantine-color-scheme='dark'] .homeFeatureCard {
  background-color: var(--mantine-color-dark-7);
  border: 1px solid var(--mantine-color-dark-1) !important;

  &:hover {
    border: 1px solid var(--mantine-color-brand-6) !important;
  }
}

.spacingToChatInput {
  height: 5rem;
  display: block;
  flex-shrink: 0;
}

.floatingChatWrapper {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: var(--mantine-color-white);
  padding-left: var(--mantine-spacing-sm);
  padding-right: var(--mantine-spacing-sm);
  padding-top: 5px;
  padding-bottom: 5px;
}

[data-mantine-color-scheme='dark'] .floatingChatWrapper{
  background-color: var(--mantine-color-dark-6);
}

.homeFeatureCardTitle {
  &::after {
    content: '';
    display: block;
    background-color: var(--mantine-color-brand-6);
    width: 64px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
  }
}

.stepCard {
  background-color: var(--mantine-color-gray-0);
  border: 1px solid var(--mantine-color-gray-3);
  min-width: 200px;

  &:hover {
    border: 1px solid var(--mantine-primary-color-filled);
  }
}

[data-mantine-color-scheme='dark'] .stepCard {
  background-color: var(--mantine-color-dark-7);
  border: 1px solid var(--mantine-color-dark-1);

  &:hover {
    border: 1px solid var(--mantine-primary-color-filled);
  }
}

.tabList {
  flex-wrap: wrap;

  &::before {
    border-bottom: none;
  }
}

.dropZone {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mantine-color-gray-1);
  border: 2px dashed var(--mantine-color-gray-3);

  &[data-accept] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-green-1);
    border: 2px dashed var(--mantine-color-green-6);
  }

  &[data-reject] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-red-1);
    border: 2px dashed var(--mantine-color-red-6);
  }
}

[data-mantine-color-scheme='dark'] .dropZone {
  background-color: var(--mantine-color-dark-6);
  border: 2px dashed var(--mantine-color-dark-3);

  &[data-accept] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-green-9);
    border: 2px dashed var(--mantine-color-green-6);
  }

  &[data-reject] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-red-9);
    border: 2px dashed var(--mantine-color-red-6);
  }
}

.dropZoneInner {
  flex: 1;
}