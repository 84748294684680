.root {
  border-radius: var(--mantine-radius-sm);
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}

.control {
  border-radius: var(--mantine-radius-sm);

  &[data-active] {
    border-radius: var(--mantine-radius-md);
  }
}

.label {
  padding-top: 5px;
  padding-bottom: 5px;
}

.item {
  border-radius: var(--mantine-radius-sm);
  border: 1px solid transparent;
  position: relative;
  transition: transform 100ms ease;

  background-color: var(--mantine-color-body);
  border-color: var(--mantine-color-gray-4);

  &[data-active] {
    //transform: scale(1.03);
    background-color: var(--mantine-color-body);
    box-shadow: var(--mantine-shadow-sm);
    border-radius: var(--mantine-radius-md);
  }
}

[data-mantine-color-scheme='dark'] .item {
  border-radius: var(--mantine-radius-sm);
  background-color: var(--mantine-color-dark-6);
  border-color: var(--mantine-color-dark-3);

  &[data-active] {
    //transform: scale(1.03);
    background-color: var(--mantine-color-body);
    box-shadow: var(--mantine-shadow-sm);
    border-radius: var(--mantine-radius-md);
  }
}

.chevron {
  margin-right: var(--mantine-spacing-xs);
  margin-left: var(--mantine-spacing-xs);

  &[data-rotate] {
    transform: rotate(-180deg);
  }
}