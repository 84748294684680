.root {
    padding-top: 80px;
    padding-bottom: 80px;
    height: 100svh;
  }
  
  .label {
    text-align: center;
    font-weight: 900;
    font-size: 220px;
    line-height: 1;
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
    color: var(--mantine-color-gray-2);
  
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: 120px;
    }
  }

  [data-mantine-color-scheme='dark'] .label {
    color: var(--mantine-color-dark-4);
  }
  
  .title {
    font-family: Greycliff CF, var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: 38px;
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: 32px;
    }
  }
  
  .description {
    max-width: 500px;
    margin: auto;
    margin-top: var(--mantine-spacing-xl);
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  }