/*
* {
  outline: 1px solid red;
}
*/

html {
  background-color: rgb(248, 249, 250);
}

.wmde-markdown {
  background-color: transparent !important;
  word-break: break-word;
}

.wmde-markdown h1 .anchor,
.wmde-markdown h2 .anchor,
.wmde-markdown h3 .anchor,
.wmde-markdown h4 .anchor,
.wmde-markdown h5 .anchor,
.wmde-markdown h6 .anchor {
  display: none;
}

.wmde-markdown-var {
  height: 100% !important;
}

.wmde-markdown>pre:has(> code.code-highlight) {
  position: relative;
}

[data-color-mode*='light'] .wmde-markdown>pre:has(> code.code-highlight) {
  background-color: var(--mantine-color-gray-3) !important;
}

.wmde-markdown>pre>code.code-highlight {
  white-space: break-spaces;
  word-break: break-all;
}

.wmde-markdown>table {
  width: auto !important;
  border-color: var(--mantine-color-gray-9) !important;
}

.wmde-markdown>table tr {
  background-color: transparent !important;
}

.wmde-markdown>table th,
.wmde-markdown>table td {
  border: 1px solid var(--mantine-color-dark-3) !important;
}

[data-color-mode*='dark'] .wmde-markdown h1,
[data-color-mode*='dark'] .wmde-markdown h2 {
  border-bottom: 1px solid var(--mantine-color-dark-3) !important;
}

// [data-color-mode*='dark'] .wmde-markdown,
// [data-color-mode*='dark'] .wmde-markdown-var,
// body[data-color-mode*='dark'] {
//   background-color: var(--mantine-color-dark-6) !important;
// }

[data-color-mode*='full-dark'] .wmde-markdown,
[data-color-mode*='full-dark'] .wmde-markdown-var,
body[data-color-mode*='full-dark'] {
  background-color: var(--mantine-color-dark-9) !important;
}

.chat-user-message.wmde-markdown a {
  color: unset !important;
}

.simpleListItem {
  position: relative
}

.simpleListItemDeleteButton {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.simpleListItem:hover .simpleListItemDeleteButton {
  display: block;
}

.start_background {
  min-height: 900px;
  background-size: cover;
  //background-image: url(./images/background.jpg);
  height: 100svh;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container>img {
  width: unset !important;
  height: unset !important;
}

.recharts-dark .recharts-default-tooltip {
  background-color: var(--mantine-color-gray-8) !important;
}

.pg-viewer-wrapper .document-container {
  color: black;
}

.pdf-viewer-container {
  text-align: center;
}

@media (max-width: 600px) {
  .center {
    min-width: auto;
    width: 100%;
  }
}

table.mdtable {
  border-collapse: collapse;
}

table.mdtable td,
table th {
  padding: 8px 8px;
  border: 1px solid var(--mantine-color-default-border);
}

table.mdtable thead {
  background: var(--mantine-color-gray-light);
  font-weight: bold;
}

table.mdtable tfoot {
  font-weight: bold;
}

svg[id^="mermaid-"] {
  min-width: 400px;
  max-width: 600px;
}

.mdchat p.mdparagraph:last-child {
  margin-block-end: 0 !important;
}

.stepFlowDark {
    .react-flow {
        background-color: var(--mantine-color-dark-9) !important;

        .react-flow__minimap {
            background-color: var(--mantine-color-dark-9) !important;
        }

        .react-flow__minimap-mask {
            fill: var(--mantine-color-dark-6) !important;
        }

        .react-flow__minimap-node {
            fill: var(--mantine-color-dark-6) !important;
        }

        .react-flow__controls {
            button {
                background-color: var(--mantine-color-dark-6) !important;
                color: var(--mantine-color-gray-6) !important;
                border-bottom: 1px solid var(--mantine-color-dark-6) !important;

                &:hover {
                    background-color: var(--mantine-color-dark-3) !important;
                    color: var(--mantine-color-dark-6) !important;
                }

                path {
                    fill: currentColor;
                }
            }
        }
    }

    .react-flow__attribution {
        color: var(--mantine-color-gray-6) !important;
        background-color: var(--mantine-color-dark-9) !important;
    }
}