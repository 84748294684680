.steps{
  padding: 1rem;
}

.separator {
  height: 2px;
  border-top: 2px solid var(--mantine-color-gray-4);
  border-radius: var(--mantine-radius-xl);
  background-color: transparent;

  &[data-active] {
    border-width: 0;
    background-image: linear-gradient(
      45deg,
      var(--mantine-primary-color-filled),
      var(--mantine-color-cyan-6)
    );
  }
}

[data-mantine-color-scheme='dark'] .separator {
  border-top: 2px solid var(--mantine-color-dark-3);

  &[data-active] {
    border-width: 0;
    background-image: linear-gradient(
      45deg,
      var(--mantine-primary-color-filled),
      var(--mantine-color-cyan-6)
    );
  }
}

.stepIcon {
  background-color: var(--mantine-color-white);
  border-color: var(--mantine-color-gray-4);
  border-width: 0;

  &[data-progress] {
    border-width: 2px;
    border-color:  var(--mantine-primary-color-filled);
  }

  &[data-completed] {
    border-width: 0;
    background-color: transparent;
    background-image: linear-gradient(
      45deg,
      var(--mantine-primary-color-filled),
      var(--mantine-color-cyan-6)
    );
  }
}

[data-mantine-color-scheme='dark'] .stepIcon {
  background-color: var(--mantine-color-dark-6);
  border-color: var(--mantine-color-dark-3);

  &[data-progress] {
    border-width: 2px;
    border-color:  var(--mantine-primary-color-filled);
  }

  &[data-completed] {
    border-width: 0;
    background-color: transparent;
    background-image: linear-gradient(
      45deg,
      var(--mantine-primary-color-filled),
      var(--mantine-color-cyan-6)
    );
  }
}

.step {
  transition: transform 150ms ease;

  &[data-progress] {
    transform: scale(1.05);
  }
}