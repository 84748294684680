.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: 42px !important;
  font-weight: 900 !important;
  line-height: 1.25 !important;
  text-align: center;
  margin: 0;
  padding: 0;
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .title {
  color: var(--mantine-color-white);
}

.subtitle {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: 32px !important;
  font-weight: 800;
  line-height: 1.25;
  text-align: center;
  margin: 0;
  padding: 0;
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .subtitle {
  color: var(--mantine-color-white);
}

.radio {
  padding: var(--mantine-spacing-xs);
}

.radioSelected {
  border-radius: var(--mantine-radius-xs);
  border: 1px solid var(--mantine-color-dark-2);
  background-color: var(--mantine-color-gray-2);
}

[data-mantine-color-scheme='dark'] .radioSelected {
  background-color: var(--mantine-color-dark-5);
}

.switchButton {
  display: flex;
  border-radius: var(--mantine-radius-sm);
  box-shadow: var(--mantine-shadow-xs);
  padding: var(--mantine-spacing-lg);
  background-color: var(--mantine-color-gray-0);
  border: 1px solid var(--mantine-color-gray-3);

  &:hover{
    border: 1px solid var(--mantine-primary-color-filled);
  }
}

[data-mantine-color-scheme='dark'] .switchButton {
  background-color: var(--mantine-color-dark-7);

  &:hover{
    border: 1px solid var(--mantine-color-brand-6);
  }
}